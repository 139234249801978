.date-range-picker {
  display: flex;
  background: #f7f7f7;
  justify-content: center;
}
  
.date-picker-container {
  text-align: center;
  background: #f7f7f7;
}

.react-datepicker {
  border-radius: 0;
  border-style: none;
}

.react-datepicker__day {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  color: #ffffff;
  background-color: #89CFF0;
}

.react-datepicker__header {
  text-align: justify;
  border-bottom: none;
  background: #f7f7f7;
}

.react-datepicker__month-container {
  background: #f7f7f7;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__day-name, .react-datepicker__day {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
  background-color: #589ac3;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  margin-top: 5px;
}

.react-datepicker__navigation--previous {
  left: auto;
  right: 15px;
}

.react-datepicker__navigation--next {
  right: -10px;
}

.react-datepicker__header__dropdown {
  margin-bottom: 10px;
}

.react-datepicker__year-dropdown-container--select {
  margin: 0;
}

.react-datepicker__month-dropdown-container--select {
  margin: 0 5px;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 2.5px 0;
}

.react-datepicker__day--today {
  font-weight: 400;
}

.react-datepicker__day--in-range {
  /* border-radius: 0; */
  background: #9DB6E9;
}

.react-datepicker__day--selected {
  font-weight: 700;
  background: #3B6CD3;
}

.react-datepicker__day--range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.react-datepicker__day--range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__day:not([aria-disabled=true]):hover {
  color: #fff;
  border-radius: 50%;
  background: #3B6CD3;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  /* border-radius: 0; */
  background: #9DB6E9;
}