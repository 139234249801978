@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap");

body {
  margin: 0;
  overflow-x: hidden;
  background: #f7f7f7 !important;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "Inter", sans-serif;
}

#root {
  font-size: 16px;
  background: #f7f7f7;
}

.publisher-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 36px;
}

.publisher-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #00726e;
}

.publisher-scrollbar::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

#explorer-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 36px;
}

#explorer-table::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #2e4063;
}

#explorer-table::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 36px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #2e4063;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 36px;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #2e4063;
}

.scroller::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

.select-menu ul::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  border-radius: 36px;
}

.select-menu ul::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #2e4063;
}

.select-menu ul::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

.autocomplete-paper ul::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  border-radius: 36px;
}

.autocomplete-paper ul::-webkit-scrollbar-thumb {
  border-radius: 36px;
  background: #2e4063;
}

.autocomplete-paper ul::-webkit-scrollbar-track {
  border-radius: 36px;
  background: #d7d8d9;
}

.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnSeparator--resizable {
  display: none !important;
}

.table-row-expanded {
  background: #f7f7f7 !important;
}

.validity-status-cell {
  padding: 0 !important;
}